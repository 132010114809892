<template>
<v-main style="height: 100vh; margin-left: auto; margin-right:"  >
  <div dark class="d-flex flex-column fill-height pa-4" style="max-width:1600px;">
    <div
      class="d-flex flex-row justify-space-between align-center pa-2"
      style="height: 15%"
    >
      <v-img
        height="90%"
        width="50%"
        position="center left"
        left
        contain
        src="@/assets/doricmor.png"
      ></v-img>
    </div>
    <v-row class="d-flex flex-row justify-space-between" style="height: 85%">
      <v-col xs="12" sm="12" md="6" class="fill-height">
        <v-card
          class="products fill-height"
          dark
          flat
          outlined
          style="overflow-y: auto"
        >
          <v-card-text class="fill-height d-flex flex-column" style="overflow-y: auto">
            <h3>
              As a visitor attraction you can choose from our extensive range of products off-the-shell - all at competitive trade prices -
              or work with us to produce exclusive products built on your brand. Either from your own artwork and images, or we can help to commission original work from our own artists.
            </h3>
            <h3 class="mt-2">Some of our current clients include:</h3>
            <h3 class="mt-5">
              <v-icon class="mr-4" color="#018e4f"> mdi-spa </v-icon>
              PS Waverley Paddle Steamer
            </h3>
            <h3 class="mt-3">
              <v-icon class="mr-4" color="#018e4f"> mdi-spa </v-icon>
              Vulcan Restoration Trust
            </h3>
            <h3 class="mt-3">
              <v-icon class="mr-4" color="#018e4f"> mdi-spa </v-icon>
              Clifton Suspension Bridge
            </h3>
            <h3 class="mt-3">
              <v-icon class="mr-4" color="#018e4f"> mdi-spa </v-icon>
              RAF Museum Hendon
            </h3>
            <h3 class="mt-5">
              We offer a personal service, small product runs, and real
              quality merchandise, all produced in Scotland.
            </h3>
          <v-spacer></v-spacer>
          <v-card-text>
            <div>
              <v-icon class="mr-4" color="#018e4f"> mdi-phone </v-icon>
              +44 (0) 131 553 2772
            </div>
            <div class="mt-4">
              <v-icon class="mr-4" color="#018e4f"> mdi-email </v-icon>
              <a style="color: #ffffff" href="mailto://sales@doricmor.com">sales@doricmor.com</a>
            </div>
          </v-card-text>
          <v-card-actions class="pb-0">
            <v-btn
              x-large
              block
              color="success"
              class="mb-4"
              to="/home"
              @click.stop="engage"
            >
              Find Out More
            </v-btn>
          </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col xs="12" sm="12" md="6" class="fill-height">
        <v-card
          dark
          class="products fill-height"
          flat
          style="overflow-y: auto"
        >
          <v-card-title>A Case Study - PS Waverley</v-card-title>
          <v-card-text>
            We produce a range of specially commissioned products for this world famous visitor attraction. Images from Scottish artists Esther Cohen, Peter McDermott and Raymond Murrray. Items from high value Giclee prints to affordable gift items
          </v-card-text>
          <v-card-text class="fill-height" style="overflow-y: auto">
            <div v-for="item of images" :key="item.type">
              <h3>{{ item.type }}</h3>
              <v-row class="my-2">
                <v-col md="4" v-for="image of item.images" :key="image">
                  <v-img contain :src="image"></v-img>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</v-main>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  metaInfo: {
    title: "Fine Art Merchandise for visitor attractions, galleries and retail",
  },
  name: "Doricmor",
  data() {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
    };
  },
  methods: {
    engage() {
      const date = new Date();
      const days = 1000;
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      var expires = date.toGMTString();
      document.cookie =
        "returningVisitor=true; expires=" + expires + "; path=/;";
    },
    login() {
      const date = new Date();
      const days = 1000;
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      var expires = date.toGMTString();
      document.cookie =
        "returningVisitor=true; expires=" + expires + "; path=/;";
    },
    goToProducts(category) {
      const obj = {};
      obj.category = [category];
      this.$store.commit("filters/setFilters", obj);
      this.$router.push("/products").catch((err) => {
        return err !== null;
      });
    },
  },
  computed: mapState({
    ...mapGetters(["isLoggedIn"]),
    images: function () {
      return [
        {
          type: "Greeting Cards",
          images: [
            this.imageUrl + "/300px/GCEC68-PS-Waverley-A-&-J-Inglis-Glasgow-1.jpg",
            this.imageUrl + "/300px/GCNRP08-PS-Waverley-1.jpg",
            this.imageUrl + "/300px/GCRM23-Waverley-1.jpg",
          ],
        },
        {
          type: "Giclee Prints",
          images: [
            this.imageUrl + "/300px/GICL-RM23.jpg",
            this.imageUrl + "/300px/GICL-RM77.jpg",
            this.imageUrl + "/300px/GICLEC17OP-PS-Waverley.jpg",
            this.imageUrl + "/300px/GICLNRP08A2-PS-Waverley.jpg",
          ],
        },
        {
          type: "Fridge Magnets",
          images: [
            this.imageUrl + "/300px/MAGEC68-PS-Waverley-A-&-J-Inglis-Glasgow-1.jpg",
            this.imageUrl + "/300px/MAGNRP08-PS-Waverley-1.jpg",
            this.imageUrl + "/300px/MAGRM23-Waverley-1.jpg",
          ],
        },
        {
          type: "Small Mounted Prints",
          images: [
            this.imageUrl + "/300px/MPEC68-PS-Waverley-A-&-J-Inglis-Glasgow-1.jpg",
            this.imageUrl + "/300px/MPNRP08-PS-Waverley-1.jpg",
            this.imageUrl + "/300px/MPRM23-Waverley-1.jpg",
          ],
        },
        {
          type: "Ceramic Mugs",
          images: [
            this.imageUrl + "/300px/MUGEC68-PS-Waverley-A-&-J-Inglis-Glasgow-1.jpg",
            this.imageUrl + "/300px/MUGNRP08-PS-Waverley-1.jpg",
            this.imageUrl + "/300px/MUGRM23-Waverley-1.jpg",
          ],
        },
        {
          type: "A3 Posters",
          images: [
            this.imageUrl + "/300px/POSTNRP08-PS-Waverley-1.jpg",
            this.imageUrl + "/300px/POSTRM23-Waverley-1.jpg",
          ],
        },
      ];
    },
    products: function () {
      return this.productTypes.map((product) => {
        return {
          title: product.replaceAll("-", " "),
          image: `${this.imageUrl}/Background-${product}-no-text-jpg.webp`,
        };
      });
    },
  }),
};
</script>
<style scoped>
.v-main,
.v-card {
  background-color: #1e1e1e;
  border: 1px solid #666;
  /* color: #ffffff */
}
.v-card.products {
  /* border: 1px solid white; */
}
</style>
